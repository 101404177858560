<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button color="primary" @click="moveToProfile()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
            {{$t("buttons.done")}}
          </ion-button>
        </ion-buttons>
        <ion-title>{{$t("profile.editMyProfile")}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">
      <ProfileEditFormSkeleton v-if="loading" />
      <div v-else>
        <ProfileEditForm v-if="profile != null" />
        <div v-else>
          <p>{{$t("errors.problemOccured")}}</p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  IonButton,
  loadingController,
  toastController,
} from "@ionic/vue";

import {
  personOutline,
  locationOutline,
  briefcaseOutline,
  schoolOutline,
  informationCircleOutline,
  homeOutline,
  chevronBackOutline,
} from "ionicons/icons";
import { ref } from "vue";

import router from "@/router";
import {
  myProfile,
  loadingMyProfile,
  loadMyProfile,
  saveMyProfile,
  validateProfileIsNok,
  validationOn,
} from "@/components/profile/";

import ProfileEditForm from "@/components/profile/edit/ProfileEditForm.vue";
import ProfileEditFormSkeleton from "@/components/profile/edit/ProfileEditFormSkeleton.vue";
import { useI18n } from 'vue-i18n';
// import {user} from "@/auth"

export default {
  name: "MyProfileEdit",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButtons,
    IonButton,
    ProfileEditForm,
    ProfileEditFormSkeleton,
  },
  setup() {
    // TODO
    // const allowedUsers = ["3IzTgLkdV7ZwuKU4bPfEgrsTHGp2", "ByD2w0e6N3YTAHeBuL9Qw7Xa7oO2", "k4rbNyfy5qWB2fAz24iAQfyxaiH3"];
    // if ( !allowedUsers.includes(user.value.uid)) {
    //   router.replace("/laska");
    // }

    
    
    const { t } = useI18n({ useScope: "global" });
    const loading = ref(loadingMyProfile);
    const profile = ref(myProfile);
    loadMyProfile();

    let uploadProcessing: any;
    const presentLoader = async () => {
      uploadProcessing = await loadingController.create({
        message: t("profile.saving"),
      });

      await uploadProcessing.present();
    };

    const openErrorToast = async (msg: string) => {
      const toast = await toastController.create({
        message: msg,
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const moveToProfile = async () => {
        if (validateProfileIsNok()) {
        validationOn.value = true;
        document.getElementById("mainInfo")?.scrollIntoView({behavior: "smooth"});
        return;
      }

      await presentLoader();
      const {result, error} = await saveMyProfile();
      await uploadProcessing.dismiss();
      if (result == 'success') {
        router.replace("/profile");
      } else {
        if (error == 'storage') {
          await openErrorToast(t("profile.savingFailedPhoto"));
          router.replace("/profile");
        } else if (error == 'database') {
          await openErrorToast(t("profile.savingFailed"));
        }
      }
    };

    return {
      // REFERENCES
      profile,
      loading,

      // FUNCTIONS
      moveToProfile,

      // ICONS
      schoolOutline,
      informationCircleOutline,
      personOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      chevronBackOutline,
    };
  },
};
</script>


<style scoped>

</style>